// src/App.js
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Noto Sans KR', sans-serif;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
    letter-spacing: 0.05em; /* 자간 설정 */
  }
`;

const darkTheme = {
    backgroundColor: '#121212',
    color: '#c0c0c0',
    containerBg: '#1e1e1e',
    titleColor: '#bb86fc',
    subtitleColor: '#03dac6',
    linkColor: '#03dac6',
    linkHoverColor: '#bb86fc'
};

const lightTheme = {
    backgroundColor: '#ffffff',
    color: '#333333',
    containerBg: '#f5f5f5',
    titleColor: '#333333',
    subtitleColor: '#555555',
    linkColor: '#1a0dab',
    linkHoverColor: '#d33'
};

const Container = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.containerBg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  line-height: 1.6;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: ${({ theme }) => theme.titleColor};
  margin: 0;
`;

const Subtitle = styled.h2`
  font-size: 1.2em;
  color: ${({ theme }) => theme.subtitleColor};
  margin-top: 10px;
  margin-bottom: 0;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h3`
  font-size: 1.5em;
  border-bottom: 1px solid ${({ theme }) => theme.titleColor};
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const SectionContent = styled.div`
  padding: 0 10px;
`

const ContentTitle = styled.span`
  font-weight: 700;
  font-size: 1.1em;
`

const List = styled.ul`
  list-style-type: none;
  padding: 10px 15px 0 15px;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
`;

const SubListItem = styled.li`
  margin-left: 10px;
  margin-bottom: 15px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.linkColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.linkHoverColor};
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.subtitleColor};
  color: ${({ theme }) => theme.backgroundColor};
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8em;
`;

const HR = styled.hr`
    border: 1px solid #a0a0a0;
`

const App = () => {
    const [theme, setTheme] = useState(darkTheme);

    useEffect(() => {
        const appTheme = localStorage.getItem('appTheme');

        if (appTheme) {
            setTheme(appTheme === 'dark' ? darkTheme : lightTheme);
        } else {
            setTheme(darkTheme);
        }
    }, []);

    const toggleTheme = () => {
        if (theme === darkTheme) {
            setTheme(lightTheme);
            localStorage.setItem('appTheme', 'light');
        } else {
            setTheme(darkTheme);
            localStorage.setItem('appTheme', 'dark');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Container>
                <ToggleButton onClick={toggleTheme}>
                    {theme !== darkTheme ? 'Dark Mode' : 'Light Mode'}
                </ToggleButton>
                <Header>
                    <ProfileImage src={"me.jpg"} alt="Profile" />
                    <Title>이동엽 (Dong-yeop Lee)</Title>
                    <Subtitle>지속적인 성장을 지향하는 개발자</Subtitle>
                </Header>
                <Section>
                    <SectionTitle>Contact Information</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>
                                <ContentTitle>• Email: </ContentTitle>
                                <Link href="mailto:ldy033000@gmail.com">ldy033000@gmail.com</Link>
                            </ListItem>
                            <ListItem>
                                <ContentTitle>• Phone: </ContentTitle>
                                010-9918-8293
                            </ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Channel</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>
                                <ContentTitle>• LinkedIn: </ContentTitle>
                                <Link href="https://linkedin.com/in/lee-dy" target="_blank" rel="noopener noreferrer">linkedin.com/in/lee-dy</Link>
                            </ListItem>
                            <ListItem>
                                <ContentTitle>• Github: </ContentTitle>
                                <Link href="https://github.com/leedy-dev" target="_blank" rel="noopener noreferrer">github.com/leedy-dev</Link>
                            </ListItem>
                            <ListItem>
                                <ContentTitle>• Website: </ContentTitle>
                                <Link href="https://dylabo.me" target="_blank" rel="noopener noreferrer">dylabo.me</Link>
                            </ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Introduce</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>
                                - 2021년 웹 개발 교육 수료 후 개발자로 입사하여 여러 웹 시스템 구축 프로젝트를 수행하였습니다.
                                주로 백엔드 개발자로서 데이터 처리와 REST API 개발을 담당하였으며,
                                때때로 프론트엔드 개발과 프로젝트 리더를 겸하면서 프로젝트의 전반적인 개발을 맡았습니다.
                            </ListItem>
                            <ListItem>
                                - 잘 질문하는 것의 중요성을 알고 있으며, 항상 배움의 마음가짐으로 업무에 빠르게 적응할 수 있습니다.
                            </ListItem>
                            <ListItem>
                                - 강한 책임감으로 끊임없이 리뷰하여 코드를 개선해나갑니다.
                                프로젝트 리더로서 이해하기 쉽고 유지보수하기 좋은 코드를 위해 코드 가이드라인을 작성하여 팀에 배포한 경험이 있습니다.
                                그리고, 일관성과 개발 생산성을 높이기 위해 코드 공통화에 집중하였습니다.
                            </ListItem>
                            <ListItem>
                                - 주도적인 성향으로 주어진 업무를 스스로 고민해보며 해결해나가는 것을 선호합니다.
                                실무의 흐름과 특성을 파악하고 사용자의 입장에서 생각하려고 노력합니다.
                                관리 시스템과 사용자 화면을 별도로 구성하면서 관리자와 사용자의 입장을 고려하여 개발하였습니다.
                            </ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Skills</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>
                                <ContentTitle>- Backend: </ContentTitle>
                                Java, Spring Boot, Spring MVC / Batch / Data JPA, Mybatis
                            </ListItem>
                            <ListItem>
                                <ContentTitle>- Frontend: </ContentTitle>
                                Javascript, Typescript, ReactJS
                            </ListItem>
                            <ListItem>
                                <ContentTitle>- RDBMS: </ContentTitle>
                                MySQL, PostgreSQL, MS SQL Server
                            </ListItem>
                            <ListItem>
                                <ContentTitle>- ETC: </ContentTitle>
                                Git, Jenkins, Docker, AWS, IntelliJ
                            </ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Experience</SectionTitle>
                    <SectionContent>
                        <span style={{ fontSize: '1.2em', fontWeight: 700 }}>스카우트</span><br/>
                        <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2024. 04. ~ </span><br/>
                        <span>(백엔드 개발, 프론트엔드 개발, 서버 관리)</span>
                        <List>
                            <ListItem>
                                <ContentTitle>- 자사 운영 사이트 기능 개발 및 유지보수</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2024. 07. ~ 2024. 09.</span><br/>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Framework, JSP, MySQL, Android, Kotlin</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 관리 지역별 구분 설계 및 개발</ListItem>
                                    <SubListItem>- 지역별 예약, 결제, 정산, 매출 등 관리 시스템 개편</SubListItem>
                                    <ListItem>• 앱 회원 탈퇴 기능 개발</ListItem>
                                    <ListItem>• 안드로이드 앱 버전 관리</ListItem>
                                    <SubListItem>- 플레이스토어 안드로이드 앱 배포</SubListItem>
                                </List>
                            </ListItem>
                            <br/>
                            <ListItem>
                                <ContentTitle>- 자사 채용 사이트 기능 개발</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2024. 06. ~ 2024. 07.</span><br/>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Framework, JPA, QueryDSL, ReactJS, MS SQL Server</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 랜덤 채용 공고 배치(스케줄) 시스템 개발</ListItem>
                                    <ListItem>• 회원별 커뮤니티 좋아요 기능 개발</ListItem>
                                    <ListItem>• SMS, Email(AWS) 알림 기능 개발</ListItem>
                                </List>
                            </ListItem>
                            <br/>
                            <ListItem>
                                <ContentTitle>- 자사 리눅스 서버 구축</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2024. 04. ~ 2024. 08.</span><br/>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Linux, Windows Server, AWS, DB Server(MySQL), Docker, Jenkins, Nginx, Git</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 리눅스 서버 및 서비스 이관</ListItem>
                                    <SubListItem>- CentOS 종료에 따라 Ubuntu 서버 구축 및 서비스 이관</SubListItem>
                                    <SubListItem>- AWS ECS, ECR, 파이프라인 시스템을 Ubuntu로 구축 및 이관</SubListItem>
                                    <ListItem>• docker, jenkins, nginx 서비스 CI/CD 시스템 구축</ListItem>
                                    <SubListItem>- Git Web Hook을 연동한 무중단 배포 구현</SubListItem>
                                    <ListItem>• MySQL Database 이관(백업 및 복원)</ListItem>
                                    <SubListItem>- DB 버전 차이에 따른 문제 해결</SubListItem>
                                    <SubListItem>- utf8md4 인코딩 문제 해결</SubListItem>
                                    <ListItem>• 윈도우 서버 IIS 및 DNS 시스템 구축</ListItem>
                                    <SubListItem>- 주 DNS, 보조 DNS 서버 구축</SubListItem>
                                </List>
                            </ListItem>
                        </List>
                        <HR />
                        <span style={{ fontSize: '1.2em', fontWeight: 700 }}>블루윙스</span><br/>
                        <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2021. 06. ~ 2023. 12.</span><br/>
                        <span>(백엔드 개발, 프론트엔드 개발, PM)</span>
                        <List>
                            <ListItem>
                                <ContentTitle>- OO의료원 직원 복지 시스템 구축 및 개발</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2023. 05. ~ 2023. 12.</span><br/>
                                <List>
                                    <strong>[내용]</strong>
                                    <ListItem>• 복지 신청 및 관리 시스템 개발</ListItem>
                                    <ListItem>• 직원 교육 관리 및 교육 이력 관리 시스템 개발</ListItem>
                                </List>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Framework, MyBatis, AngularJS, MS SQL Server</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 전 직원 (약 6천명) 사용 복지 홈페이지 구축</ListItem>
                                    <ListItem>• PM(Project Manager) 역할 담당</ListItem>
                                    <SubListItem>- 프로젝트 일정(WBS) 관리 및 요구사항 관리</SubListItem>
                                    <SubListItem>- 개발 일정 관리 및 인력 구성</SubListItem>
                                    <SubListItem>- 전체 테이블 설계 및 ERD 작성</SubListItem>
                                    <ListItem>• FAQ형 챗봇 개발</ListItem>
                                    <SubListItem>- 챗봇 콘텐츠 테이블 설계</SubListItem>
                                    <SubListItem>- 챗봇 메세지 화면 개발 (사용자 메세지 입력 또는 선택)</SubListItem>
                                    <ListItem>• 동영상 교육 화면 및 API 개발</ListItem>
                                    <SubListItem>- 직원 교육 및 교육 이력 관리</SubListItem>
                                    <SubListItem>- 웹 동영상 플레이어 개발 (사용자 제어 제한, 마지막 시청 지점 등)</SubListItem>
                                </List>
                            </ListItem>
                            <br/>
                            <ListItem>
                                <ContentTitle>- OO엔터테인먼트 기업 유튜브 통계 화면 개발</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2023. 07. ~ 2023. 07.</span><br/>
                                <List>
                                    <strong>[내용]</strong>
                                    <ListItem>• 통계 데이터 쿼리 작성</ListItem>
                                    <ListItem>• 데이터 조회 및 차트 개발</ListItem>
                                </List>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Boot, MyBatis, Vue, MariaDB</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 기간, 방송사, 프로그램 등의 조건에 따라 데이터 조회</ListItem>
                                    <SubListItem>- 서브 쿼리 및 여러 JOIN 문이 포함된 복잡한 쿼리문 작성</SubListItem>
                                    <ListItem>• 조회한 데이터를 원형 차트, 막대 차트 등의 차트로 구성</ListItem>
                                </List>
                            </ListItem>
                            <br/>
                            <ListItem>
                                <ContentTitle>- OO의료원 직원 및 외부인력 전자근로계약 시스템 개발</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2022. 11. ~ 2023. 04.</span><br/>
                                <List>
                                    <strong>[내용]</strong>
                                    <ListItem>• 계약 및 계약 파일 관리 화면 및 API 개발</ListItem>
                                    <ListItem>• 계약 이력 관리 화면 및 API 개발</ListItem>
                                    <ListItem>• PC 및 모바일 서명 시스템 개발</ListItem>
                                    <ListItem>• 카카오톡, 문자, Email 발송 서비스 개발</ListItem>
                                </List>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Framework, MyBatis, AngularJS, MS SQL Server</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• 전체 테이블 설계 및 ERD 작성</ListItem>
                                    <ListItem>• 파일 업로드 및 다운로드 개발</ListItem>
                                    <SubListItem>- 계약 파일(PDF) 업로드 및 다운로드</SubListItem>
                                    <SubListItem>- 다건 계약 파일 압축하여 다운로드</SubListItem>
                                    <SubListItem>- 전체 계약 엑셀 다운로드</SubListItem>
                                    <ListItem>• 카카오톡, 문자, Email 서비스 개발</ListItem>
                                    <ListItem>• PDF 파일에 PC 및 모바일 서명 시스템 개발</ListItem>
                                </List>
                            </ListItem>
                            <br/>
                            <ListItem>
                                <ContentTitle>- OO호텔 비대면 통합 관리 시스템 개발</ContentTitle><br/>
                                <span style={{ fontStyle: 'italic', color: '#A4A4A4', textDecoration: "underline" }}>2021. 06. ~ 2022. 10.</span><br/>
                                <List>
                                    <strong>[내용]</strong>
                                    <ListItem>• 수분양 관리 및 수분양자 개인 관리 API 개발</ListItem>
                                    <ListItem>• 홈페이지 메뉴, 카테고리 및 콘텐츠 관리 API 개발</ListItem>
                                    <ListItem>• 호텔 시설 예약 및 예약 관리 API 개발</ListItem>
                                </List>
                                <List>
                                    <strong>[사용기술]</strong>
                                    <ListItem>• Java, Spring Boot, JPA, QueryDSL, Typescript, ReactJS, PostgreSQL</ListItem>
                                </List>
                                <List>
                                    <strong>[주요 개발 및 기여 내용]</strong>
                                    <ListItem>• Multi Module 프로젝트 구성 및 개발</ListItem>
                                    <SubListItem>- 주요 서비스를 분할해 프로젝트를 구성하여 개발(핵심, 공통, 권한, 콘텐츠 등)</SubListItem>
                                    <ListItem>• 다국어 (4개 국어) 시스템 개발 (DB 반영)</ListItem>
                                    <SubListItem>- Embedded 필드를 사용하여 다국어 필드 공통화</SubListItem>
                                    <ListItem>• JPA Entity 설계</ListItem>
                                    <SubListItem>- 단방향, 양방향 관계를 고려한 Entity 구성</SubListItem>
                                    <SubListItem>- 1:N 쿼리 발생 문제 해결</SubListItem>
                                    <ListItem>• SSE(Server-Sent-Event) 단방향 통신 개발</ListItem>
                                    <SubListItem>- 실시간으로 프론트에 알림 전달</SubListItem>
                                </List>
                            </ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Education</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>• 금오공과대학교 기계시스템공학과 (2013. 03. ~ 2020. 02.)</ListItem>
                            <ListItem>• KH정보교육원 (2020. 10. ~ 2021. 03.)</ListItem>
                        </List>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionTitle>Certifications</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>• 정보처리기사 (2021. 06.)</ListItem>
                        </List>
                    </SectionContent>
                </Section>
                {/*<Section>
                    <SectionTitle>Goals</SectionTitle>
                    <SectionContent>
                        <List>
                            <ListItem>- 단기 목표: 돋보이는 개발자로서 Git 오픈소스에 기여하는 것이 목표입니다.</ListItem>
                            <ListItem>- 장기 목표: 믿음직한 개발자로서 여러 사람들의 멘토가 되는 것이 목표입니다.</ListItem>
                        </List>
                    </SectionContent>
                </Section>*/}
            </Container>
        </ThemeProvider>
    );
}

export default App;
